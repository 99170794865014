import React, { useState } from "react";
import { graphql } from "gatsby";
import {
  StyledDisplayToggles,
  StyledDiscoContainer,
  StyledToggleRow,
  StyledAlbumCollectionContainer,
  StyledAlbumContainer,
} from "../../assets/PageStyles/discographyIndex";

export const getAlbums = graphql`
  query getAllAlbums {
    allContentfulAlbum {
      edges {
        node {
          id
          frontCoverArt {
            file {
              url
            }
          }
          releaseDate
          title
        }
      }
    }
  }
`;

const toSlug = string =>
  string
    .split(" ")
    .map(word => word.toLowerCase())
    .join("-");

const Discography = ({ data }) => {
  const defaultDisplay = "list";
  const isBrowser = typeof window !== "undefined";
  const [display, setDisplay] = useState(
    isBrowser ? window.localStorage.getItem("display") : defaultDisplay
  );

  const toggleDisplay = () => {
    const nextDisplay = display === "grid" ? "list" : "grid";
    window.localStorage.setItem("display", nextDisplay);
    setDisplay(nextDisplay);
  };

  const {
    allContentfulAlbum: { edges },
  } = data;
  const sortedAlbums = edges.sort((a, b) => {
    const aFormatted = new Date(a.node.releaseDate);
    const bFormatted = new Date(b.node.releaseDate);
    // @ts-ignore
    return bFormatted - aFormatted;
  });

  return (
    <StyledDiscoContainer>
      <StyledToggleRow display={display}>
        <StyledDisplayToggles
          onClick={toggleDisplay}
          disabled={display === "grid"}
          type="button"
        >
          Grid
        </StyledDisplayToggles>
        <StyledDisplayToggles
          onClick={toggleDisplay}
          disabled={display === "list"}
          type="button"
        >
          List
        </StyledDisplayToggles>
      </StyledToggleRow>

      {/* Main Container  */}
      <StyledAlbumCollectionContainer display={display}>
        {sortedAlbums.map(({ node }) => (
          <StyledAlbumContainer
            to={`/discography/${toSlug(node.title)}`}
            key={node.id}
            display={display}
          >
            <img src={node.frontCoverArt.file.url} />
            {node.title}
          </StyledAlbumContainer>
        ))}
      </StyledAlbumCollectionContainer>
    </StyledDiscoContainer>
  );
};

export default Discography;
