import styled from "styled-components";
import { Link } from 'gatsby';
import { StyledHeaderButton } from "../../components/Header";

// @ts-ignore
export const StyledDisplayToggles = styled(StyledHeaderButton) <{
  disabled?: boolean;
}>`
  color: ${props =>
    props.disabled ? props.theme.colors.text : props.theme.colors.disabled};
  padding: 0 20px;
  &:first-of-type {
    padding-left: 0;
  }
  &:hover {
    color: ${props => props.theme.colors.text};
  }
`;

export const StyledDiscoContainer = styled.div`
  height: calc(100% - 108px);
  width: calc(100% - ${props => props.theme.layout.gutter * 2}px);
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};

  padding: ${props => props.theme.layout.gutter}px;
  padding-bottom: ${props => props.theme.layout.gutter * 2}px;
  transition: all 0.25s ease-in-out;
`;

export const StyledToggleRow = styled.div<{ display: string }>`
  width: 100%;
  padding-bottom: ${props => props.theme.layout.baseLine * 3}px;
  ${({ display, theme }) =>
    display === "list"
      ? `
        border-bottom: 2px solid ${theme.colors.text};
      `
      : ``}
`;

export const StyledAlbumCollectionContainer = styled.div<{ display: string }>`
  width: 100%;
  color: ${props => props.theme.colors.text};

  ${({ display }) =>
    display === "grid"
      ? `
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 40px;
        @media (max-width: 1000px) {
          grid-template-columns: 1fr 1fr;
        };
        @media (max-width: 640px) {
          grid-template-columns: 1fr;
        };
      `
      : `
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      `}
`;

export const StyledAlbumContainer = styled(Link) <{ display: string }>`
    width: 100%;
    height: auto;
    text-decoration: none;
    font-family: ${props => props.theme.type.fontFamily};
    color: ${props => props.theme.colors.text};

    img {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    
  ${({ display, theme }) =>
    display === "grid"
      ? `
        margin: 24px 0 0 0;
        font-size: ${theme.type.nav.fontSize}px;
        line-height: ${theme.type.nav.lineHeight}px;
        img {
          width: 100%;
          margin-bottom: 24px;
        }
      `
      : `
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: ${theme.layout.gutter}px 0;
        border-bottom: 2px solid ${theme.colors.text};
        margin: 0;
        
        font-size: 32px;
        line-height: 38px;
        
        @media (min-width: 415px) {
          font-size: 64px;
          line-height: 75px;
        }

        @media (min-width: 1024px) {
          font-size: 100px;
          line-height: 117px;
        }
        

        img {
          height: 38px;
          width: 38px;
          margin-right: 40px;

          @media (min-width: 415px) {
            height: 80px;
            width: 80px;
          }

          @media (min-width: 1024px) {
            height: 120px;
            width: 120px;
          }
        };
      `}
`;